import { enqueueSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import i18next from '../i18.config';

export const formatErrors = (error: ApolloError): string[] => {
  const messages = error.graphQLErrors.map(e => {
    if (e.extensions.error === 'RequiredParameterError') {
      return i18next.t('common.errors.requiredParameter', { param: e.message });
    } else {
      return e.message;
    }
  });

  return messages;
};

// TODO: [wnguyen] abstract this to default behaviour on apollo client
export const onApolloError = (error: ApolloError): void => {
  Sentry.captureException(error);
  formatErrors(error).forEach(m => enqueueSnackbar(m, { variant: 'error' }));
};
